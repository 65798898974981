.ap{
	&--section-vs{
		padding: 70px 0;
		background: $second-brand-color;
		z-index: unset;

		.section-header {
			text-align: center;
			max-height: unset;
			width: 100%;
			margin-bottom: 40px;

			.title{
				margin-bottom: 12px;
			}

			.p{
				max-width: 500px;
				margin: 0 auto;
			}
		}

		.p--secondary.center{
			text-align: center;
			max-width: 900px;
			margin: 0 auto;
			b{
				color: $dark-blue;
			}
		}
	}

	&--items-list{
		display: flex;
		align-items: stretch;
		justify-content: center;
		gap: 60px 40px;
		margin-bottom: 50px;
		@include break-max(990px){
			flex-direction: column;
			align-items: center;
			margin-left: -20px;
			margin-right: -20px;
		}
	}
	&--item{
		position: relative;
		width: 100%;
		max-width: 480px;
		padding: 45px 35px;
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		@include break-max(990px){
			max-width: 640px;
		}

		&-label{
			position: absolute;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			top: -45px;
			right: -45px;
			width: 158px;
			height: 158px;
			background: #FFFFFF;
			border-radius: 50%;
			box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);

			font-family: $mulish;
			font-weight: bold;
			@include break-max($mob){
				position: relative;
				margin: -75px auto 20px auto;
				left: 0;
				right: 0;
				top: 0;
			}

			&_title{
				text-transform: uppercase;
				font-size: 20px;
				line-height: 1;
				margin-bottom: 6px;
			}
			&_subtitle{
				font-size: 14px;
				color: $dark-blue;
			}
		}

		&-logo{
			display: block;
			text-align: center;
			line-height: 1;

			img{
				max-height: 48px;
			}
		}

		&-header{
			display: block;
			text-align: center;
			margin-bottom: 30px;
			line-height: 1;
		}
		.btn-bar{
			text-align: center;
			padding-top: 25px;
			margin-bottom: 0;
			margin-top: auto;
		}

		&-def{
			background: #fff;

			.btn-bar{
				text-align: right;
				img{
					margin-bottom: -30px;
				}
			}
		}
		&-pro{
			background: $category-medical;
		}
	}

	&--item-benefit-list{
		&__item{
			display: flex;
			align-items: flex-start;
			gap: 12px;
			margin-bottom: 12px;
			color: $dark-blue;

			&:before{
				content: '';
				display: inline-block;
				height: 18px;
				width: 20px;
				line-height: 1;
				background: url("../../../images/icons/btn-icon/check-lite--blue.svg") no-repeat center;
				background-size: contain;
				position: relative;
				bottom: -0.3em;
			}
		}
	}


	//section payment provider
	&--section-pp{
		display: flex;
		align-items: center;
		min-height: 50vh;
		position: relative;
		padding: 60px 0;
		background: #fff;
		z-index: 2;

		@include break-min($mob){
			padding: 80px 0;
		}
		@include break-max($tab){
			text-align: center;
		}

		.btn-bar{
			padding-top: 20px;
		}
		.pp--accordion-toggler{
			&-txt{
				display: none;
				&-full{
					display: block;
				}
				&-less{
					display: none;
				}
			}
			&.active{
				.pp--accordion-toggler-txt{
					&-full{
						display: none;
					}
					&-less{
						display: block;
					}
				}
			}
		}

		.features-payments{
			&__list {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				transition: .3s ease;
				margin: 0 -10px;
				@include break-min($mob){
					gap: 0 18px;
				}
			}
			&__item {
				position: relative;
				max-width: 150px;
				@include break-min($mob){
					max-width: 170px;
				}

				&.payfort,
				&.alipay,
				&.robokassa,
				&.paygate,
				&.paymentwall,
				&.paypal_plus,
				&.paysafecard,
				&.stripe,
				&.dwolla,
				&.pagar-me,
				&.blockchain,
				&.gocoin,
				&.kortais,
				&.zozo{
					display: none !important;
				}
			}
			&__item-image {
				width: 100%;
			}
		}


		.ap--row{
				flex-direction: column;
		}

		.ap--col-data{
			max-width: 500px;
			padding: 10px 0;
			text-align: center;
			.title--h2{
				margin-bottom: 22px;
			}

			.p{
				margin-bottom: 20px;
			}

			.btn-bar{
				padding-top: 10px;
			}
		}
	}

	// section feature development
	&--section-feature-development{
		padding: 60px 0;
		background: #fff;

		.section-header{
			@include break-min(900px){
				text-align: left;
			}
			title{
				margin-bottom: 12px;
			}

			.lite{
				font-weight: 300;
			}
		}

		.ap--row{
			@include break-max(900px){
				flex-direction: column;
			}
		}

		.ap--col-data{
			width: 100%;
			max-width: 500px;
		}

		.ap--col-picture{
			max-width: 400px;
			.ap--picture{
				img{
					max-height: 410px;
					width: auto;
				}
			}
		}
	}
	&--fd-list{
		display: flex;
		align-items: stretch;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 -6px;
		@include break-max(370px){
			margin-left: 0;
			margin-right: 0;
		}
		&-item{
			width: calc(50% - 12px);
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: flex-start;
			padding: 58px 20px 20px;
			text-align: center;
			min-height: 40px;
			margin: 6px;
			background: #FFFFFF;
			box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
			border-radius: 16px;
			aspect-ratio: 1/1;
			@include break-max(990px){
				aspect-ratio: unset;
			}
			@include break-max(370px){
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
			p{
				color: $secondary-text-color;
				line-height: 1.22;

				b{
					display: block;
					color: $dark-blue;
					padding-bottom: 5px;
				}
			}

			.icon{
				min-width: 25px;
				text-align: center;
				line-height: 1;
				margin-bottom: 12px;
			}

			svg{
				height: 22px;

				path{
					fill: $primary-blue;
				}
			}
		}
	}

	//section form
	&--section-stay-tuned{
		padding: 60px 0;
		background: $second-brand-color;
		@include break-max($mob){
			text-align: center;
		}

		.section-header{
			@include break-min($mob){
				text-align: left;
			}
		}

		.stay-tuned--form{
			.form-group{
				margin-bottom: 20px;
			}
			.btn-bar{
				padding-top: 10px;
			}
		}

		.ap--col-data{
			max-width: 480px;
			margin-bottom: 30px;
			@include break-min($mob){
				min-width: 360px;
				margin-bottom: 0;
			}
		}

		.ap--picture-fd{
			img{
				max-height: 425px;
			}
		}
	}

	&--section-should-use{
		position: relative;
		padding: 60px 0;
		background: $second-brand-color;
		z-index: 2;

	.ap--row{
		flex-direction: row-reverse;
		@include break-max(880px){
			flex-direction: column;
			text-align: center;
		}
	}
		.ap--col-data{
			.title{
				margin-bottom: 22px;
			}
			.btn-bar{
				padding-top: 30px;
			}
			@include break-max(880px){
				padding-bottom: 20px;
			}
		}
		.ap--picture-should-use{
			padding-top: 70px;
			padding-right: 100px;
			width: 100%;
			text-align: left;
			max-width: 500px;
			min-width: 500px;
			margin-right: 20px;
			@include break-max($tab){
				max-width: 440px;
				min-width: 440px;
				padding-right: 70px;
				margin-right: 0;
			}
			@include break-max($mob){
				padding-right: 20px;
				min-width: unset;
				max-width: 440px;
			}
			img{
				max-height: 350px;
				width: auto;

				&.should-use--img{
					position: absolute;
					&-part2{
						top: 0;
						right: -60px;
						max-height: 200px;
						@include break-max($tab){
							right: -20px;
						}
					}
					&-part3{
						max-height: 113px;
						bottom: 20%;
						right: 50px;
						@include break-max($tab){
							right: 0;
						}
					}
				}
			}
		}
	}
}

//support section
.section--help-center--ap{
	padding: 80px 0;
	overflow: hidden;

	.top-shape{
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 101%;
		min-width: 700px;
		left: 50%;
		transform: translate(-50%, -38%);
	}

	.cursive{
		padding-top: 25px;
		padding-left: 20px;
		color: $social-link-color;

		.arrow{
			transform: rotate(-16deg);
			left: -20px;
		}
	}

	.help-center{
		&--row{
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px 50px;
			flex-wrap: wrap;
			margin: 0 auto;
			@include break-max(900px){
				flex-direction: column;
				max-width: 700px;
				text-align: center;
			}
		}
		&--picture {
			min-width: 400px;
			width: 400px;
			margin: 0 -20px;
			background: url('../../../images/support-assistant/support4.jpg') no-repeat;
			background-size: 460px;
			background-position: top 5px center;
			aspect-ratio: 1/1;
			@include break-min($mob){
				min-width: 460px;
				width: 460px;
				background-size: 530px;
				margin: 0;
			}
			@include break-max($mob480){
				min-width: unset;
				width: calc(100% + 40px);
				background-size: cover;
				background-position: center;
			}
		}
		&--data{
			max-width: 610px;
			flex: 1;

			.title--h2{
				margin-bottom: 22px;
			}

			.p{
				margin-bottom: 12px;
			}

			.btn-bar{
				display: inline-flex;
				flex-direction: column;
				padding-top: 12px;
				@include break-max(800px){
					align-items: center;
				}

				&--row{
					display: inline-flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					gap: 8px;
				}
			}
		}
	}
}


.popup--login-form .modal-dialog {
	@include break-min(800px){
		width: 100%;
		max-width: 830px;

		.ap--row{
			flex-direction: row-reverse;
		}
	}

	.title--h2{
		font-size: 25px;
	}

	.p--subtitle{
		margin-bottom: 15px;
	}

	.ap--col-picture{
		margin-bottom: 22px;
		@include break-min(800px){
			text-align: left;
			margin-bottom: 0;
		}

		.form-group{
			margin-bottom: 15px;
		}
		.btn-bar{
			padding-top: 25px;
		}
	}


	.ap--fd-list{
		display: block;
		margin: 0;

		&-item{
			display: flex;
			flex-direction: row;
			padding: 0;
			box-shadow: none;
			border-radius: 0;
			margin: 0 0 7px 0;
			gap: 10px;
			aspect-ratio: unset;
			width: 100%;
			text-align: left;

			.icon{
				margin: 0;
			}

			b{
				font-family: $mulish;
				display: inline;
			}
		}
	}
}

#form-modal-success{
	.modal__content{
		text-align: center;
		.alert-message{
			text-align: center;
			justify-content: center;
		}
	}
}