@import "../base/vars";

.ap {
	&--section {
		position: relative;

		.section-header {
			max-width: 800px;
			text-align: center;
			margin-bottom: 40px;
			margin-left: auto;
			margin-right: auto;
		}

		b {
			font-weight: bold;
			font-family: $mulish;
		}

		.cursive {
			color: $social-link-color;

			.arrow {
				display: inline-block;
				position: absolute;
				bottom: 0;
				left: 0;
				height: 60px;
				width: 28px;
				background: url('../../../images/sbpay/arrow.svg') no-repeat;

				&--grey {
					background: url('../../../images/sbpay/arrow--grey.svg') no-repeat;
				}
			}
		}
	}

	&--picture {
		position: relative;
		display: inline-block;
		text-align: center;
	}

	&--row {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		gap: 10px 55px;
		@include break-max(800px) {
			flex-direction: column-reverse;
		}
	}

	&--col {
		width: 100%;
		flex: 1;

		&-picture {
			text-align: center;
		}
	}
}

.hero-screen{
	padding-top: 90px;
	padding-bottom: 70px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content:center;
	background: #fff;
	color: $dark-blue;
	@include break-max($tab){
		text-align: center;
	}

	.promo-video__btn{
		@include break-min($mob){
			left: 57%;
		}
	}

	.title,
	.p{
		margin-bottom: 12px;
	}

	.p{
		max-width: 550px;
	}

	.cursive{
		top: -25px;
		left: -30px;
		position: relative;
		display: inline-flex;
		padding: 0 50px;
		@include break-max($mob){
			position: absolute;
			top: auto;
			left: auto;
			bottom: 0;
			right: -50px;
		}
		&--txt{
			color: $dark-blue;
		}
		.arrow{
			transform: rotate(-35deg);
			left: -30px;
		}
	}

	.ap--row{
		flex-direction: column;
		@include break-min($tab){
			flex-direction: row;
			justify-content: flex-start;
			flex-wrap: nowrap;
		}
	}

	&--content{
		width: 100%;
		max-width: 410px;
		margin-bottom: 20px;
		@include break-min($tab){
			position: relative;
			margin-bottom: 0;
			min-width: 410px;
		}

		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: url("../../../images/sbpay/logo--bg.svg") no-repeat;
			background-size: cover;
			background-position: center;
			pointer-events: none;
			@include break-min($tab){
				width: 500px;
				height: 500px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-size: contain;
			}
		}

		.btn-bar{
			position: relative;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			gap: 8px 12px;
			padding-top: 20px;
			z-index: 2;
		}
	}

	&--mockup{

	}

	&--picture{
		position: relative;
		display: inline-block;
		text-align: center;
		z-index: 2;
		@include break-min($tab){
			display: block;
			flex: 1;
			padding-left: 120px;
			min-width: 600px;
		}
		@include break-max($tab-xl){
			padding-left: 60px;
		}
		@include break-max($mob){
			padding: 0;
		}

		.phantom-block{
			@include break-min($mob){
				height: 60px;
			}
		}

		.sbp-logo{
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			right: 0;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			background: #FFFFFF;
			box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
			@include break-min($mob){
				top: -20px;
				right: -20px;
				width: 120px;
				height: 120px;
			}
		}

		img{
			position: relative;
			@include break-min($mob){
				max-width: 560px;
			}
		}

		.main-section--mob-main_mockup{
			display: none;
			@include break-min($mob){
				display: block;
			}
		}

		.main-section--mob-mockup{
			@include break-min($mob){
				max-height: 350px;
				position: absolute;
				bottom: 80px;
				left: 0;
			}
			@include break-max($mob){
				max-width: 320px;
				width: 100%;
				height: auto;
			}
		}

		.main-section--mob-mockup-mob{
			display: block;
			max-width: 320px;
			width: 100%;
			height: auto;
		}

		.main-section--card{
			position: absolute;
			max-height: 228px;
			bottom: 0;
			right: -140px;
			@include break-max($mob){
				right: -80px;
			}
		}
	}
}
