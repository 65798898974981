//@import "../../../scss/base/vars";
//@import "../../../scss/base/components/animate";
@import "../base/vars";

@-webkit-keyframes zoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}
@keyframes zoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}
.zoomIn {
	-webkit-animation-name: zoomIn;
	animation-name: zoomIn;
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.animated.delay-1s {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.animated.delay-2s {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.animated.delay-3s {
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
}

.animated.delay-4s {
	-webkit-animation-delay: 4s;
	animation-delay: 4s;
}

.animated.delay-5s {
	-webkit-animation-delay: 5s;
	animation-delay: 5s;
}

.animated.fast {
	-webkit-animation-duration: 800ms;
	animation-duration: 800ms;
}

.animated.faster {
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
}

.animated.slow {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}

.animated.slower {
	-webkit-animation-duration: 3s;
	animation-duration: 3s;
}

@import "hero-screen";
@import "content";